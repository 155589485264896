<template>
    <div class="pd20x flex1">
        <AddClasses :visible="openClasses" @close="openClasses = false" :options="options" :data="currentItem" :edit="editClass" :allSubject="allSubject" @update="getCrouseList"></AddClasses>
        <EditCertify :visible="show_certify" :parentId="radioList.length?radioList[currentIndex].id:null" :tags="tabList" @close="updateCate"></EditCertify>
        <div class="contentBox h100pct ofA">
            <div class="filter">
                <div>
                    <div class="radioGroup">
                        <div class="radioItem" v-for="(item, index) in radioList" :key="index" :class="{active: index == currentIndex}" @click="changeRadio(index, item.id)">{{item.name}}</div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="filter" style="padding:0 0 10px" v-if="currentIndex !=0">
                <div class="radio-line" style="border-bottom:0">
                    <div class="radio-line-item" v-for="(item, index) in tabList" :key="index" :class="{active: index == classifyIndex}" @click="changeClassify(index, item.id)">{{item.name}}</div>
                </div>
                <div>
                    <a-button type="primary" class="mr10px"  @click="show_certify = true" v-if="currentIndex == 1"> 编辑分类</a-button>
                    <a-button type="primary" class="mr10px"  @click="addClassesbag"> 新建课程包 </a-button>
                </div>
            </div>
             <!-- <div class="filter" style="padding:0 20px 10px">
                <div> {{radioList[currentIndex]}}（5条） </div>
                <div>
                    <a-button type="primary" class="mr10px"  @click="visible = true"> 一键回复 </a-button>
                    <a-button @click="deleteItem">删除</a-button>
                </div>
            </div> -->
            <a-table
                :columns="columns"
                :data-source="data"
                :pagination="pagination"
                :rowKey="'id'"
                @change="changePage"
            >
                <template slot="name" slot-scope="text, record">
                    <div class="imgWrap"><img :src="record.img" alt=""></div>
                    <div>{{record.name}}</div>
                </template>
                <template slot="operation" slot-scope="text,record">
                    <i class="z-icon editB" @click="openClasses = true; currentItem = record; editClass = true"></i>
                    <i class="z-icon delete" @click="deleteItem(record.id)"></i>
                </template>
            </a-table>
        </div>
    </div>
</template>
<script>
const columns = [
  {
    title: '课程包名称',
    dataIndex: 'name',
    align:'center',
    width:200,
    scopedSlots: { customRender: 'name' },
  }, {
    title: '添加时间',
    dataIndex: 'create_time',
    align:'center',
  },{
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
    align:'center',
  },
];
import EditCertify from '@/components/exam/editCertify'
import AddClasses from '@/components/exam/addClasses'
import * as http from '@/libs/examapi'
export default {
    name: 'classifyset',
    data(){
        // this.classifyList = ['会计类', '师范类', '工程类', '医学类' ]
        // this.degreeList = ['公共课', '高起专', '专升本', '自学考试', '考研双证' ]
        return{
            currentIndex: 0,
            classifyIndex: 0,
            visible: false,
            classifyShow: false,
            tabList:[],
            allSubject:[],
            options:[],
            columns,
            filter:{
                cate_id: 0,
            },
            pagination:{
                page: 1,
                size: '15',
                pageSize: 15
            },
            radioList:[],
            classifyList:[],
            degreeList:[],
            data:[],
            show_certify: false,
            //课程包
            openClasses: false,
            currentItem:'',
            editClass:false
        }
    },
    components: { EditCertify, AddClasses },
    mounted(){
        this.$nextTick(()=>{
            this.getAllSubject()
            this.getCates()
            this.getData().then(res=>{
                this.getCrouseList()
            })
        })
    },
    methods:{
        getCates(){
            return new Promise((resolve, reject)=>{
                http.get_subject_cate().then(res=>{
                    this.options = res
                    resolve()
                }).catch(error=>{
                    reject()
                })
            }) 
        },
        getData(){
           return new Promise((resolve, reject)=>{
                http.get_cate().then(res=>{
                    this.radioList = res.map(el=>({
                        id: el.id,
                        name: el.name
                    }))
                    this.radioList.unshift({id:-1,name:'全部'})
                    resolve()
                }).catch(error=>{
                    reject()
                    console.log(error)
                })
           })
        },
        getCrouseList(){
             let request = {...this.filter,...this.pagination}
            if(this.filter.cate_id == 0){
               request.cate_id = null
            }
            http.get_crouse_list(request).then(res=>{
                const pagination = { ...this.pagination }
                pagination.total = res.total;
                this.data = res.data
                this.pagination = pagination;
               
            }).catch(error=>{
                console.log(error)
            })
        },
        getCateS(id){
           return new Promise((resolve, reject)=>{
                http.get_cate_s({id}).then(res=>{
                    resolve(res)
                }).catch(error=>{
                    reject(error)
                })
           }) 
        },
        changeRadio(index, id){
            this.pagination.page = 1
            this.currentIndex = index
            this.classifyIndex = 0
            this.filter.cate_id = ''
            if(index == 1 ){
                if(!this.classifyList.length){
                    this.getCateS(id).then(res=>{
                        this.classifyList = res.map(el=>({
                            id: el.id,
                            name: el.name
                        }))
                        this.tabList = this._.cloneDeep(this.classifyList)
                        this.filter.cate_id = this.tabList[0].id
                        this.getCrouseList()
                    })
                }else{
                    this.tabList = this._.cloneDeep(this.classifyList)
                    this.filter.cate_id = this.tabList[0].id
                    this.getCrouseList()
                }
            }else if( index == 2){
                if(!this.degreeList.length){
                    this.getCateS(id).then(res=>{
                        this.degreeList = res.map(el=>({
                            id: el.id,
                            name: el.name
                        }))
                        this.tabList = this._.cloneDeep(this.degreeList)
                         this.filter.cate_id = this.tabList[0].id
                        this.getCrouseList()
                    })
                }else{
                    this.tabList = this._.cloneDeep(this.degreeList)
                    this.filter.cate_id = this.tabList[0].id
                    this.getCrouseList()
                }
            }else{
                this.filter.cate_id = ''
                this.getCrouseList()
            }
        },
        updateCate(){
            this.classifyIndex = 0
            this.show_certify = false;
            this.getCateS(this.radioList[this.currentIndex].id).then(res=>{
                this.tabList = res.map(el=>({
                    id: el.id,
                    name: el.name
                }))
                this.currentIndex == 1?this.classifyList = this._.cloneDeep(this.tabList):this.degreeList = this._.cloneDeep(this.tabList)
            })
        },
        deleteItem(id){
            let _this = this
            this.$confirm({
                title: '是否确定删除该条信息?',
                centered: true,
                onOk() {
                    http.del_crouse_bag({id}).then(res=>{
                        _this.getCrouseList()
                    }).catch(error=>{
                        console.log(error)
                    })
                },
            });
        },
        changeClassify(index, id){
            this.classifyIndex = index
            if(this.filter.cate_id != id){
                this.pagination.page = 1
                this.filter.cate_id = id
                this.getCrouseList()
            }
        },
        addClassesbag(){
            this.openClasses = true;
            this.currentItem = null; 
            this.editClass = false
        },
        getAllSubject(){
            return new Promise((resolve, reject)=>{
                http.get_allSubject().then(res=>{
                    this.allSubject = res
                     resolve()
                }).catch(error=>{
                    reject()
                })
            })
        },
        changePage({current}){
            this.pagination.page = current
            this.getCrouseList()
        },
    },
}
</script>
<style scoped>
.imgWrap{
    width: 120px;
    height: 70px;
    overflow: hidden;
    margin: 0 auto 5px;
    border-radius: 4px;
}
</style>